import { FC } from "react";
import { FileUploader } from "react-drag-drop-files";
import { IInput } from "../form/Input";
import FileList from "./FileList";
import { icons } from "../../icons/IconSet";
import Button from "../form/Button";

const FileUpload: FC<IInput & {
  buttonTitle?: string,
  filesState?: File[],
  setFileState?: (files: File[]) => unknown,
  disabled?: boolean,
}> = ({
  buttonTitle,
  filesState,
  setFileState,
  disabled = false,
  ...props
}) => {

  return (
    <>  
      <FileUploader
        classes="file-uploader"  
        label="Upload or drop a file"      
        multiple
        handleChange={(files: File[]) => setFileState && setFileState([
          ...(filesState ||[]),
          ...Array.from(files),
        ])}
        name="msg-file-loader"
        // types={["JPEG", "PNG", "GIF"]}
        children={(
          <div className="
            relative cursor-pointer transition-all flex w-full item-center rounded-md overflow-hidden 
            bg-[#F3F3F3] p-5
          ">
            <div className="flex flex-col gap-2">
              {!!filesState?.length 
                ? <FileList files={filesState} setFiles={setFileState} />
                : <div className="text-gray-600">No photos uploaded</div>
              }                 
              <Button type="submit">Upload Photo</Button>
              <div className="text-[#B80505] font-semibold">15 MB file limit</div>
            </div>   
          </div>
        )}
      />
      {/* {!!filesState?.length && (
        <div className="mt-4">
          <FileList files={filesState} setFiles={setFileState} />
        </div>
      )} */}
    </>
  );
};

export default FileUpload;
