import { FC } from "react";
import { icons } from "../../icons/IconSet";
import { ITicket } from "../../model/ITicket";
import parseISOToString from "../../utils/parseISOToString";
import LoadedFileList from "../files/LoadedFileList";

const TicketMessages : FC<{ ticket: ITicket }> = ({ ticket }) => (
  <ul role="list" className="space-y-6">
    {ticket?.messages?.map((message, i) => (
      <li className="relative flex gap-x-4" key={`msg-${message.id}`}>
        {!!ticket?.messages?.[i + 1] && (
          <div className="absolute left-0 top-0 flex w-6 justify-center -bottom-6">
            <div className="w-px bg-gray-200" />
          </div>
        )}
        <div className="relative flex h-6 w-6 flex-none items-center justify-center">
          <div className={`
            h-3.5 w-3.5 rounded-full  ring-1 ring-gray-300
            ${message.messageType === 'System'
              ? 'bg-primary ring-primary_dark'
              : 'bg-gray-100 ring-gray-300'}
          `} />
        </div>
        
        {message.messageType === 'System'
          ? (
            <>
              <p className="flex-auto py-0.5 leading-5 text-gray-500">
                <span className="font-medium text-gray-900">{message?.fromUser?.name ?? 'Manager'}</span>&nbsp;
                {message?.actionType || ''} the ticket.
              </p>
              <time className="flex-none py-0.5 leading-5 text-gray-500">{parseISOToString(message.created)}</time>
            </>
          ) : (
            <div className=" relative flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
              <div className="flex justify-between gap-x-4">
                <div className="py-0.5 leading-5 text-gray-500">
                  <span className="font-medium text-gray-900">
                    {message?.fromUser?.name ? 'CS Agent' : 'You'}
                  </span> wrote
                </div>
                <time  className="flex-none py-0.5 leading-5 text-gray-500">{parseISOToString(message.created)}</time>
              </div>
              <div className=" leading-6 text-gray-500 mt-2">
                {message?.fromUser === null
                  ? message.messageText
                  : <div dangerouslySetInnerHTML={{ __html: message.messageText }} />
                }
              </div>
              {!!message.attachments?.length && (
                <div className="flex mt-4 gap-2 py-4 px-2 relative -left-[11px]">
                  {icons('attach', { width: '16px', stroke: 'gray' })}               
                  <LoadedFileList files={message.attachments} />
                </div>
              )}              
            </div>
          )
        }        
    </li>
    ))}
  </ul>
);

export default TicketMessages;
